/**
 *  Do not remove the comment below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,700|Roboto:100,300,400,500,700,700italic,400italic);
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../../bower_components/material-design-iconfont/iconfont/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(../../bower_components/material-design-iconfont/iconfont/MaterialIcons-Regular.woff2) format("woff2"), url(../../bower_components/material-design-iconfont/iconfont/MaterialIcons-Regular.woff) format("woff"), url(../../bower_components/material-design-iconfont/iconfont/MaterialIcons-Regular.ttf) format("truetype");
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

html {
  font-family: 'Roboto Slab', serif;
}

html, body {
  background-color: #eee;
}

.roboto, #prescreen, #survey {
  font-family: 'Roboto', sans-serif;
}

.quicksand, #prescreen .content .options .md-button, #survey .fancy-header .fancy-title, #survey .question .prompt, #survey .question .autofill, #survey .question .map .location-name, #survey .question .map .location-address, #survey .question .answers md-radio-button, #survey .question .choices md-radio-button, #survey .question .idk, #survey .question .minutes, #survey .question .repeater .specify, #survey .question .slider, #survey .intro, #survey .choices md-select, #survey .ratings md-select, #survey .checks md-select, #survey .stars md-select, #survey .choices select, #survey .ratings select, #survey .checks select, #survey .stars select, #survey .choices select option, #survey .ratings select option, #survey .checks select option, #survey .stars select option, #survey .ratings .rate, #survey .ratings .answer md-radio-button, #survey .checks .rate, #survey .stars .rate, #survey .stars .answer md-radio-button, #survey .responses .rate, #survey .responses .answer md-radio-button {
  font-family: 'Quicksand', sans-serif;
}

[layout=row] {
  flex-direction: row;
}

body.firefox.mac .md-ripple-container {
  display: none !important;
}

body.edge.not_mobile #plugshare {
  overflow: hidden;
}

body.mobile #maps #menu .filter .filter-label:hover {
  background-color: #eaedf0;
  border: 1px solid #efefef;
}

body.mobile #maps #menu .filter input:checked ~ .filter-label {
  background-color: #f7f7f7;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #eee;
  border-left: 1px solid #ddd;
}

body.mobile #maps #clear-filters {
  top: 45px;
}

.clearfix:after, #survey .branding:after, #survey .choices .choice:after, #survey .ratings .choice:after, #survey .checks .choice:after, #survey .stars .choice:after {
  content: " ";
  display: table;
  clear: both;
}

.link {
  color: #0000EE;
  border-bottom: 1px solid;
  cursor: pointer;
}

.unlink {
  text-decoration: none;
  color: inherit;
}

.highlight {
  text-decoration: none;
  border-bottom: 1px dotted #ccc;
  cursor: pointer;
}

.highlight:hover {
  color: #4093bd;
  border-bottom: 1px dotted #4093bd;
}

.centerAll {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.capitalize {
  text-transform: capitalize;
}

.smooth, #prescreen, #prescreen .content .options .md-button, #survey .question .answers md-radio-button, #survey .question .choices md-radio-button, #survey .question .idk, #survey .question .minutes, #survey .next, #survey .ratings .answer md-radio-button, #survey .stars .answer md-radio-button, #survey .responses .choice, #survey .responses .answer md-radio-button {
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;
}

:focus {
  outline: none !important;
}

#lightbox {
  user-select: none;
}

.nox {
  overflow-x: hidden;
}

.nopad {
  padding: 0;
}

.thirds {
  width: calc((100% / 3) - 0.1px);
}

.shadow {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.pointer {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

* {
  -webkit-overflow-scrolling: touch;
}

.disable {
  background: #f4f4f4;
}

a.md-button.md-docs-light-theme.md-raised, .md-button.md-docs-light-theme.md-raised {
  color: inherit;
  background-color: inherit;
}

a.md-button.md-default-theme.md-raised:not([disabled]) md-icon, a.md-button.md-raised:not([disabled]) md-icon, .md-button.md-default-theme.md-raised:not([disabled]) md-icon, .md-button.md-raised:not([disabled]) md-icon {
  color: inherit;
}

md-content.md-default-theme, md-content {
  background-color: inherit;
}

md-toolbar.md-default-theme {
  background-color: black;
}

md-slider[md-discrete] .md-sign,
md-slider[md-discrete] .md-sign:after {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
}

body {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body #noscroll {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.instant * {
  transition: none !important;
}

.md-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: rgba(0, 0, 0, 0.05);
}

.md-button.md-first {
  margin-left: 0;
}

h3.md-title {
  margin: 0;
}

.layout-fill {
  display: block;
}

.ng-hide.ng-hide-animate {
  display: none !important;
}

a.selected .md-button {
  font-weight: bold;
  color: #fff;
  background-color: #3f51b5;
}

a.selected .md-button:hover {
  background-color: #4d5fc3 !important;
}

.md-padding {
  padding: 8px 15px;
}

.md-submit {
  background-color: #6fbb0b !important;
  color: #fff !important;
}

.md-submit:hover {
  background-color: #77d200 !important;
}

md-icon {
  color: inherit;
  position: relative;
}

.text-icon {
  margin-left: 3px;
  bottom: 1px;
}

md-button {
  margin: 0;
}

md-option {
  background-color: #fff;
  transition: background .15s linear;
}

md-option:hover {
  background-color: #eee;
}

md-menu-item {
  position: relative;
}

md-menu-item > .md-button md-icon {
  margin: 0;
}

md-select-menu {
  background-color: white;
}

md-select-menu, md-select-menu md-content {
  max-height: 300px;
}

.md-menu-backdrop {
  display: none;
}

.md-scroll-mask {
  display: none;
}

md-toast {
  cursor: pointer;
}

.md-toolbar-tools > .md-button:last-child {
  margin-right: -4px;
}

@media (max-width: 959px) and (min-width: 0) and (orientation: portrait) {
  .md-toolbar-tools > .md-button:last-child {
    margin-right: -8px;
  }
}

@media (max-width: 959px) and (min-width: 0) and (orientation: landscape) {
  .md-toolbar-tools > .md-button:last-child {
    margin-right: -12px;
  }
}

.inline {
  display: inline-block;
  vertical-align: top;
}

.center-cell {
  display: table;
}

.center-cell > div {
  display: table-cell;
  vertical-align: middle;
}

.truncate, #survey .responses .choice.collapse .rate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn, #survey .zigzag {
  animation-fill-mode: forwards;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeOut {
  animation-fill-mode: forwards;
  animation: fadeOut 1s ease-in-out;
}

@keyframes slideInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slideInBottom, #survey .question.nocheckin {
  animation-fill-mode: forwards;
  animation: slideInBottom 1s ease-in-out;
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(40px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slideInLeft {
  animation-fill-mode: forwards;
  animation: slideInLeft 1s ease-in-out;
}

@keyframes phase-in-out {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.phase, #survey .confetti {
  animation-fill-mode: forwards;
  -webkit-animation: phase-in-out 5s ease-in-out;
  /* Safari 4+ */
  -moz-animation: phase-in-out 5s ease-in-out;
  /* Fx 5+ */
  -o-animation: phase-in-out 5s ease-in-out;
  /* Opera 12+ */
  animation: phase-in-out 5s ease-in-out;
  /* IE 10+, Fx 29+ */
}

@keyframes breath {
  0% {
    animation-timing-function: cubic-bezier(0.9647, 0.2413, -0.0705, 0.7911);
    transform: scale(0.95);
  }
  51% {
    animation-timing-function: cubic-bezier(0.9226, 0.2631, -0.0308, 0.7628);
    transform: scale(1.02994);
  }
  100% {
    transform: scale(0.95);
  }
}

.breath, #survey .finish .logo .logo-container {
  animation: breath 1s infinite linear;
}

@keyframes bounce-in {
  0% {
    animation-timing-function: cubic-bezier(0.0383, 0.3388, 0.0421, 0.6652);
    transform: matrix(0.01, 0, 0, 0.01, 0, 0);
    opacity: 1;
  }
  4.7% {
    animation-timing-function: cubic-bezier(0.3296, 0.3336, 0.5772, 0.6672);
    transform: matrix(0.556, 0, 0, 0.556, 0, 0);
    opacity: 1;
  }
  8.4% {
    animation-timing-function: cubic-bezier(0.0781, 0.1617, 0.0874, 0.9301);
    transform: matrix(0.979, 0, 0, 0.979, 0, 0);
    opacity: 1;
  }
  12.4% {
    animation-timing-function: cubic-bezier(0.8341, 0.1496, 0.8634, 0.7673);
    transform: matrix(1.153, 0, 0, 1.153, 0, 0);
    opacity: 1;
  }
  16.3% {
    animation-timing-function: cubic-bezier(0.3264, 0.3332, 0.5758, 0.6695);
    transform: matrix(1.008, 0, 0, 1.008, 0, 0);
    opacity: 1;
  }
  21.2% {
    animation-timing-function: cubic-bezier(0.0921, 0.1883, 0.3277, 1);
    transform: matrix(0.704, 0, 0, 0.704, 0, 0);
    opacity: 1;
  }
  24.5% {
    animation-timing-function: cubic-bezier(0.6905, 0.0944, 0.8759, 0.7624);
    transform: matrix(0.626, 0, 0, 0.626, 0, 0);
    opacity: 1;
  }
  27.7% {
    animation-timing-function: cubic-bezier(0.3688, 0.332, 0.624, 0.6684);
    transform: matrix(0.704, 0, 0, 0.704, 0, 0);
    opacity: 1;
  }
  32.6% {
    animation-timing-function: cubic-bezier(0.1368, 0.2364, 0.2451, 0.9049);
    transform: matrix(0.958, 0, 0, 0.958, 0, 0);
    opacity: 1;
  }
  37.4% {
    animation-timing-function: cubic-bezier(0.5936, 0.0846, 0.3495, 1);
    transform: matrix(1.085, 0, 0, 1.085, 0, 0);
    opacity: 1;
  }
  49.5% {
    animation-timing-function: cubic-bezier(0.5522, 0.0981, 0.3807, 1);
    transform: matrix(0.802, 0, 0, 0.802, 0, 0);
    opacity: 1;
  }
  62.4% {
    animation-timing-function: cubic-bezier(0.4497, 0.1349, 0.4911, 1);
    transform: matrix(1.044, 0, 0, 1.044, 0, 0);
    opacity: 1;
  }
  74.1% {
    animation-timing-function: cubic-bezier(0.429, 0, 0.5239, 1);
    transform: matrix(0.914, 0, 0, 0.914, 0, 0);
    opacity: 1;
  }
  87% {
    animation-timing-function: cubic-bezier(0.3501, 0, 0.6422, 1);
    transform: matrix(1.013, 0, 0, 1.013, 0, 0);
    opacity: 1;
  }
  95.8% {
    animation-timing-function: cubic-bezier(0.3653, 0, 0.6776, 1);
    transform: matrix(0.992, 0, 0, 0.992, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
}

.bounce-in, #survey .finish .logo .logo-bounce {
  animation: bounce-in 1s 1 linear;
  animation-fill-mode: forwards;
  transform: matrix(0.01, 0, 0, 0.01, 0, 0);
}

#main {
  position: relative;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2);
}

md-virtual-repeat-container {
  display: none !important;
}

@-webkit-keyframes focus-pulse {
  0% {
    background: #f7f7f7;
  }
  50% {
    background: #e2ebff;
  }
  100% {
    background: #f7f7f7;
  }
}

.pulse {
  -webkit-animation: focus-pulse 2s linear infinite;
  /* Safari 4+ */
  -moz-animation: focus-pulse 2s linear infinite;
  /* Fx 5+ */
  -o-animation: focus-pulse 2s linear infinite;
  /* Opera 12+ */
  animation: focus-pulse 2s linear infinite;
  /* IE 10+, Fx 29+ */
}

.noselect, md-icon, #survey .finish .logo, #survey .confetti, #survey .ratings .rate, #survey .checks .rate, #survey .stars .rate, #survey .responses .rate {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.custom-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

.custom-scroll::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #aaa;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: .5em;
  background: #efefef;
  border: none;
  border-radius: 3px;
  padding: 1em 2em 1em 1em;
  font-size: 1em;
}

.select-container {
  position: relative;
  display: inline;
}

.select-container:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
}

.select-container:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: .5em;
  right: .75em;
  border-top: 8px solid black;
  opacity: 0.5;
}

select::-ms-expand {
  display: none;
}

#prescreen {
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: 100vh;
  top: 0;
  right: -100%;
  z-index: 9999;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  opacity: 0;
  overflow: hidden;
}

#prescreen.survey {
  right: 20px;
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  #prescreen.survey {
    right: 0;
    width: 100%;
  }
}

#prescreen .header {
  color: #fff;
  background-color: #003ca6;
  font-weight: bold;
  padding: 15px 20px;
}

#prescreen .content {
  padding: 15px 20px;
}

#prescreen .content .question {
  margin-bottom: 5px;
  font-size: 0.9em;
}

#prescreen .content .address {
  font-weight: bold;
  color: #555;
}

#prescreen .content .address .name {
  font-size: 1.1em;
  color: #333;
}

#prescreen .content .map {
  width: 100%;
  text-align: center;
  color: #999;
  background-color: #eee;
  border-radius: 3px;
  padding: 50px 0;
  margin-top: 10px;
}

#prescreen .content .choices .md-button {
  border: 1px solid #ccc;
}

#prescreen .content .view {
  display: inline-block;
  padding: 10px;
  margin: -10px;
}

#prescreen .content .bold-link {
  color: blue;
  font-weight: bold;
}

#prescreen .content .bold-link:hover {
  opacity: 0.6;
  cursor: pointer;
}

#prescreen .content .options {
  margin-top: 20px;
}

#prescreen .content .options .md-button {
  display: block;
  border: 1px solid #003ca6;
  padding: 0 10px;
  width: 100%;
  text-align: left;
  margin: 0;
  margin-bottom: -1px;
  text-transform: none;
  background-color: rgba(243, 242, 255, 0.75);
  color: #003ca6;
}

#prescreen .content .options .md-button:first-of-type {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

#prescreen .content .options .md-button:last-of-type {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

#prescreen .content .options .md-button.md-checked {
  background-color: #003ca6;
  color: #fff;
}

#prescreen .content .options .md-button .md-container {
  display: none;
}

#prescreen .content .options .md-button .md-label {
  margin: 0;
  font-size: 0.8em;
  font-weight: 500;
  display: block;
}

.md-dialog-content.centerScreen {
  position: absolute;
  top: 40%;
  left: 0;
  transform: translateY(-50%);
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
}

#survey {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  padding: 24px;
  background-color: #fff;
  margin-top: -4px;
}

#survey md-progress-linear {
  top: 0;
  bottom: auto;
}

#survey .close {
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px !important;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.15);
}

#survey .close:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

#survey .close:disabled {
  pointer-events: none;
}

#survey .form-title {
  font-size: 1.4em;
  font-weight: bold;
  color: #003ca6;
  margin: 0;
}

#survey .form-title .form-title-text {
  border-bottom: 4px solid;
  padding-bottom: 3px;
  margin-top: 10px;
  margin-bottom: 10px;
}

#survey .form-title h2 {
  font-size: inherit;
}

#survey .error {
  color: red;
  margin-top: 25px;
  text-align: center;
  font-size: 0.9em;
  font-weight: bold;
}

#survey .tip {
  background-color: #809abe;
  color: #fff;
  margin: 0 8px;
  padding: 8px 12px;
  margin-bottom: 8px;
  border-radius: 3px;
}

#survey .fancy-header {
  margin-bottom: 40px;
}

#survey .fancy-header .top-background {
  position: absolute;
  top: -25px;
  left: 0;
  width: calc(100% + 48px);
  background-color: #003ca6;
  background-image: url(../images/wave-header.jpg);
  background-size: cover;
  background-position: bottom;
  height: 115px;
  z-index: -2;
  margin: 0 -24px;
}

#survey .fancy-header .fancy-title {
  color: #d9d9f4;
  font-size: 1.2em;
  font-weight: 500;
  text-shadow: 1px 1px 7px rgba(136, 132, 200, 0.7);
  text-align: center;
  padding-bottom: 20px;
  position: relative;
}

#survey .finish {
  text-align: center;
  min-height: 170px;
}

#survey .finish .logo {
  margin-top: 20px;
}

#survey .finish .logo .logo-bounce {
  animation-delay: 0.75s;
}

#survey .finish .logo .logo-container {
  animation-delay: 1.5s;
  width: 100px;
  border-radius: 25px;
  margin: 0 auto;
}

#survey .finish .logo .logo-container:hover {
  background-color: #fff;
  cursor: pointer;
}

#survey .finish .logo .logo-container:hover img {
  opacity: 0.9;
}

#survey .finish .logo .logo-container:active img {
  opacity: 0.7;
}

#survey .finish .logo .logo-container img {
  width: 100px;
}

#survey .confetti {
  animation-delay: 0.75s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: url("../images/confetti.svg");
}

#survey .confetti.replay {
  animation-delay: 0s;
  animation: phase-in-out 3s ease-in-out;
  /* IE 10+, Fx 29+ */
}

#survey .zigzag {
  opacity: 0;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
  margin: 10px 0;
  z-index: -3;
}

#survey .question {
  position: relative;
  background: #fff;
  padding: 0 40px 20px;
  margin: -20px -40px -20px;
}

#survey .question.nocheckin {
  opacity: 0;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}

@media only screen and (max-width: 420px) {
  #survey .question.nocheckin {
    position: absolute;
    bottom: 10vh;
    left: 0;
    margin: 0;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }
}

#survey .question .contribute {
  font-size: 0.9em;
  text-transform: uppercase;
  color: #999;
  letter-spacing: 1px;
}

#survey .question .single-question {
  position: relative;
}

#survey .question .no-more-questions {
  padding-bottom: 50px;
}

#survey .question .prompt {
  margin: 13px 10px;
  font-size: 1.2em;
  font-weight: 700;
  color: #003ca6;
}

#survey .question .prompt .thanks {
  margin-top: 20px;
}

#survey .question .autofill {
  font-size: 0.8em;
  font-weight: 500;
  color: #555;
  background-color: #eee;
  border-radius: 3px;
  padding: 5px 11%;
}

#survey .question .map {
  width: calc(100% + 48px);
  margin: 0px -24px;
  border-bottom: 1px solid #d7e2f7;
}

#survey .question .map #gmaps {
  width: 100%;
  height: 200px;
  background-color: #eee;
  text-align: center;
}

#survey .question .map iframe {
  margin: 0;
}

#survey .question .map .location-name {
  color: #fff;
  background-color: #003ca6;
  padding: 12px 24px;
  min-height: 48px;
  box-sizing: border-box;
  font-size: 1.2em;
  font-weight: 500;
}

#survey .question .map .location-address {
  color: #003ca6;
  background-color: #f2f5f9;
  padding: 12px 24px;
  min-height: 44px;
  box-sizing: border-box;
}

#survey .question .answers {
  display: block;
  margin-top: 20px;
}

#survey .question .answers md-radio-group {
  display: flex;
  flex-wrap: wrap;
}

#survey .question .answers md-radio-button {
  flex: 1 1 0px;
  border: 1px solid #003ca6;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  font-size: 1.3em;
  margin: 0;
  background-color: rgba(243, 242, 255, 0.75);
  color: #003ca6;
  margin-left: -1px;
}

#survey .question .answers md-radio-button:first-of-type {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

#survey .question .answers md-radio-button:last-of-type {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

#survey .question .answers md-radio-button.md-checked {
  background-color: #003ca6;
  color: #fff;
}

#survey .question .answers md-radio-button .md-container {
  display: none;
}

#survey .question .answers md-radio-button .md-label {
  margin: 0;
  font-size: 0.8em;
  font-weight: 500;
  padding-bottom: 4px;
}

#survey .question .choices {
  margin-top: 20px;
}

#survey .question .choices md-radio-button {
  border: 1px solid #003ca6;
  padding: 10px 15px;
  text-align: left;
  font-size: 1.3em;
  margin: 0;
  margin-bottom: -1px;
  background-color: rgba(243, 242, 255, 0.75);
  color: #003ca6;
}

#survey .question .choices md-radio-button:first-of-type {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

#survey .question .choices md-radio-button:last-of-type {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

#survey .question .choices md-radio-button.md-checked {
  background-color: #003ca6;
  color: #fff;
}

@media only screen and (max-width: 360px) {
  #survey .question .choices md-radio-button.smaller {
    font-size: 1.2em;
  }
}

#survey .question .choices md-radio-button .md-container {
  display: none;
}

#survey .question .choices md-radio-button .md-label {
  margin: 0;
  font-size: 0.8em;
  font-weight: 500;
  display: block;
}

#survey .question .choices md-radio-button .sublabel {
  font-size: 0.8em;
}

#survey .question .more {
  color: blue;
  font-weight: bold;
  font-size: 0.8em;
  margin-top: 10px;
  display: inline-block;
}

#survey .question .more:hover {
  opacity: 0.6;
  cursor: pointer;
}

#survey .question .skip {
  color: #999;
  font-weight: 500;
  font-size: 0.8em;
  position: absolute;
  right: -10px;
  bottom: -15px;
  padding: 10px;
}

#survey .question .skip:hover {
  opacity: 0.6;
  cursor: pointer;
}

#survey .question .choice {
  padding-left: 10px;
}

#survey .question .choice label {
  padding-left: 10px;
}

#survey .question .idk, #survey .question .minutes {
  border: 1px solid #003ca6;
  padding: 10px 15px;
  text-align: center;
  background-color: rgba(243, 242, 255, 0.75);
  color: #003ca6;
  font-weight: 500;
  border-radius: 3px;
  width: calc(100% - 90px);
  margin: 0 auto;
  margin-top: 20px;
  display: inline-block;
  cursor: pointer;
}

#survey .question .idk.selected, #survey .question .minutes.selected {
  background-color: #003ca6;
  color: #fff;
}

#survey .question .idk.charge {
  margin: 20px auto 0;
  text-align: center;
  display: block;
}

#survey .question .repeater {
  position: relative;
}

#survey .question .repeater .other {
  padding-bottom: 35px;
}

#survey .question .repeater .specify {
  position: absolute;
  bottom: 10px;
  left: 15px;
  padding: 0 5px;
  width: 70%;
}

#survey .question .choices .repeater:first-of-type .choice, #survey .question .choices .repeater:first-of-type md-radio-button, #survey .question .checks .repeater:first-of-type .choice, #survey .question .checks .repeater:first-of-type md-radio-button {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

#survey .question .choices .repeater:last-of-type .choice, #survey .question .choices .repeater:last-of-type md-radio-button, #survey .question .checks .repeater:last-of-type .choice, #survey .question .checks .repeater:last-of-type md-radio-button {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

#survey .question .choices .repeater .choice, #survey .question .choices .repeater md-radio-button, #survey .question .checks .repeater .choice, #survey .question .checks .repeater md-radio-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

#survey .question .slider {
  margin-top: 20px;
  background-color: #f7f7f7;
  padding: 15px 20px 0 20px;
  border-radius: 3px;
}

#survey .question .slider .label {
  margin-bottom: 22px;
  font-size: 0.8em;
  font-weight: 500;
  display: inline-block;
  background-color: #f1f1f1;
  padding: 3px 7px;
  border-radius: 3px;
}

#survey .question .slider md-slider .md-track-container {
  height: 4px;
}

#survey .question .slider md-slider .md-thumb {
  top: 15px;
}

#survey .intro {
  font-weight: 500;
  color: #003ca6;
  text-align: justify;
  padding-top: 15px;
}

#survey .branding {
  margin-top: 20px;
}

#survey .branding .logo img {
  max-width: 100%;
}

#survey .branding .tagline {
  width: calc(100% - 155px);
  float: left;
  font-size: 0.8em;
  font-weight: bold;
  padding-left: 5px;
  position: relative;
  top: -5px;
  right: -5px;
  color: #cf1f2f;
}

#survey .start {
  color: #003ca6;
  border: 1px solid #003ca6;
  padding: 5px 15px;
  width: 100%;
  margin: 20px 0 0;
  letter-spacing: 2px;
}

#survey .next {
  border: 1px solid #003ca6;
  padding: 5px 15px;
  width: 100%;
  margin: 20px 0 0;
  letter-spacing: 2px;
  box-shadow: none;
  background-color: #003ca6;
  color: #fff;
}

#survey .next.incomplete {
  background-color: #ddd;
  color: #003ca6;
}

#survey .next:disabled {
  background-color: #e6e6e6;
  color: #003ca6;
}

#survey .exit {
  background-color: rgba(250, 250, 250, 0.6);
  color: #003ca6;
}

#survey .choices label, #survey .ratings label, #survey .checks label, #survey .stars label {
  text-align: left;
}

#survey .choices select, #survey .ratings select, #survey .checks select, #survey .stars select {
  font-weight: 500;
  text-align-last: center;
  color: #003ca6;
  background-color: #e6e6e6;
  border: 1px solid #003ca6;
  padding: 10px 15px;
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: -1px;
}

#survey .choices select.selectable, #survey .ratings select.selectable, #survey .checks select.selectable, #survey .stars select.selectable {
  background-color: rgba(243, 242, 255, 0.75);
}

#survey .choices select.filled, #survey .ratings select.filled, #survey .checks select.filled, #survey .stars select.filled {
  background-color: rgba(240, 225, 255, 0.75);
}

#survey .choices select option, #survey .ratings select option, #survey .checks select option, #survey .stars select option {
  text-align: center;
}

#survey .choices input[type=number], #survey .ratings input[type=number], #survey .checks input[type=number], #survey .stars input[type=number] {
  color: #003ca6;
  border: 1px solid #003ca6;
  padding: 10px 15px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  text-align: center;
}

#survey .choices .choice, #survey .ratings .choice, #survey .checks .choice, #survey .stars .choice {
  border: 1px solid #003ca6;
  padding: 10px 15px;
  text-align: center;
  margin: 0;
  margin-bottom: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(243, 242, 255, 0.75);
  color: #003ca6;
}

#survey .choices .choice:first-of-type, #survey .ratings .choice:first-of-type, #survey .checks .choice:first-of-type, #survey .stars .choice:first-of-type {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

#survey .choices .choice:last-of-type, #survey .ratings .choice:last-of-type, #survey .checks .choice:last-of-type, #survey .stars .choice:last-of-type {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

#survey .choices textarea, #survey .ratings textarea, #survey .checks textarea, #survey .stars textarea {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border: 1px solid #003ca6;
  padding: 10px 15px;
  box-sizing: border-box;
  border-radius: 3px;
}

#survey .ratings {
  margin-top: 20px;
}

#survey .ratings .rate {
  width: calc(100% - 80px);
  float: left;
  text-align: left;
  font-weight: 500;
}

#survey .ratings .answer {
  width: 80px;
  float: right;
  text-align: right;
}

#survey .ratings .answer md-radio-button {
  display: inline-block;
  margin-bottom: 0;
  font-size: 1.4em;
  color: #c4cddb;
  margin-left: 15px;
}

#survey .ratings .answer md-radio-button:hover {
  color: #a4b2c9;
  cursor: pointer;
}

#survey .ratings .answer md-radio-button.md-checked .fa-thumbs-up {
  color: #27b400;
  text-shadow: 1px 1px 10px rgba(123, 255, 87, 0.5);
}

#survey .ratings .answer md-radio-button.md-checked .fa-thumbs-down {
  color: #e17748;
  text-shadow: 1px 1px 10px rgba(246, 171, 137, 0.5);
}

#survey .ratings .answer md-radio-button .md-container {
  display: none;
}

#survey .ratings .answer md-radio-button .md-label {
  margin: 0;
  font-size: 1.1em;
  display: block;
}

#survey .checks .rate {
  width: calc(100% - 40px);
  float: left;
  text-align: left;
  font-weight: 500;
}

#survey .checks .choice {
  padding: 7px 10px;
}

#survey .checks .choice.exclusive {
  background-color: #e6e6e6;
}

#survey .checks .choice.exclusive .fa {
  color: #d5d5d5;
}

#survey .checks .answer {
  width: 40px;
  float: right;
  text-align: right;
  font-size: 1.8em;
}

#survey .checks md-checkbox .fa {
  color: #e4e4e4;
}

#survey .checks md-checkbox .fa-square {
  display: block;
}

#survey .checks md-checkbox .fa-check-square {
  display: none;
}

#survey .checks md-checkbox.md-checked {
  background-color: #003ca6;
  color: #fff;
  border-top: 1px solid #4b81e3;
}

#survey .checks md-checkbox.md-checked .md-label .rate {
  color: #fff !important;
}

#survey .checks md-checkbox.md-checked .fa-square {
  display: none;
}

#survey .checks md-checkbox.md-checked .fa-check-square {
  display: block;
}

#survey .checks md-checkbox.md-checked .fa {
  color: #2dcf00;
  text-shadow: 1px 1px 10px rgba(123, 255, 87, 0.15);
}

#survey .checks md-checkbox .md-container {
  display: none;
}

#survey .checks md-checkbox .md-label {
  margin: 0;
  display: block;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#survey .checks md-checkbox md-icon {
  color: #c4cddb;
}

#survey .stars .choice {
  display: block;
  padding-bottom: 5px;
  padding: 7px 15px;
}

#survey .stars .choice:nth-of-type(even) {
  background-color: rgba(230, 229, 244, 0.75);
}

#survey .stars .rate {
  text-align: center;
  font-weight: 500;
  font-size: 1.1em;
}

#survey .stars .answer {
  margin-top: 3px;
}

#survey .stars .answer md-radio-button {
  display: inline-block;
  margin-bottom: 0;
  font-size: 2em;
  color: #c4cddb;
  padding: 7px 7px 9px 7px;
  margin: -5px 0 -7px 0;
}

#survey .stars .answer md-radio-button:hover {
  color: #a4b2c9;
  cursor: pointer;
}

#survey .stars .answer md-radio-button.on, #survey .stars .answer md-radio-button.md-checked {
  color: #003ca6;
  text-shadow: 0px 0px 10px rgba(154, 184, 240, 0.5);
}

#survey .stars .answer md-radio-button .md-container {
  display: none;
}

#survey .stars .answer md-radio-button .md-label {
  margin: 0;
  display: block;
}

#survey .responses {
  text-align: left;
}

#survey .responses .choice {
  display: block;
  padding: 7px 15px;
  padding-bottom: 12px;
  background-color: #f4f4f4;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  max-height: 300px;
}

#survey .responses .choice.collapse {
  max-height: 18px;
}

#survey .responses .choice.collapse .rate {
  padding-right: 30px;
}

#survey .responses .choice.collapse md-radio-button {
  opacity: 0;
}

#survey .responses .rate {
  font-weight: 500;
  font-size: 1.1em;
  margin-bottom: 8px;
  position: relative;
  padding-right: 10px;
}

#survey .responses .rate .fa-plus, #survey .responses .rate .fa-minus {
  font-size: 0.7em;
  margin-right: 5px;
  top: -2px;
  left: 0;
  position: relative;
  color: #333;
}

#survey .responses .rate .fa-check {
  position: absolute;
  top: 3px;
  right: 0;
  color: #00c600;
}

#survey .responses .answer {
  margin-top: 3px;
}

#survey .responses .answer md-radio-button {
  background-color: #fff;
  margin-bottom: 0;
  padding: 7px 7px 9px 7px;
  margin-top: -1px;
  border: 1px solid #ccc;
}

#survey .responses .answer md-radio-button:hover {
  background-color: #e4e8ef;
  cursor: pointer;
}

#survey .responses .answer md-radio-button.on, #survey .responses .answer md-radio-button.md-checked {
  background-color: #003ca6;
  color: #fff;
  text-shadow: 0px 0px 10px rgba(154, 184, 240, 0.5);
}

#survey .responses .answer md-radio-button .md-container {
  display: none;
}

#survey .responses .answer md-radio-button .md-label {
  margin: 0;
  margin-left: 10px;
  display: block;
}

#survey .responses .answer md-radio-button:first-of-type {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

#survey .responses .answer md-radio-button:last-of-type {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

#survey .progress {
  bottom: 0;
  top: auto;
  width: 100%;
  left: 0;
}

#survey .progress .md-bar2 {
  background-color: #00b000;
}

.ios .choices select, .safari .choices select {
  margin-bottom: -3px !important;
}

.sprite {
  transform: scale(0.5);
  transform-origin: 0 0;
  margin: 0 auto;
}

.sprite.network {
  width: 60px;
  height: 30px;
}

.sprite.outlet {
  width: 48px;
  height: 48px;
}

.sprite.menu {
  width: 40px;
  height: 40px;
}

.sprite.legend {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin: auto;
}

.sprite.marker {
  transform: scale(0.375);
  width: 24px;
  height: 33px;
  float: left;
  margin: 2px 10px 0px 0px;
}

.icon-card-large {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/icons.png?05232018);
  background-position: -80px 0px;
  width: 80px;
  height: 80px;
}

.icon-clock-large {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/icons.png?05232018);
  background-position: -160px -80px;
  width: 80px;
  height: 80px;
}

.icon-filter {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/icons.png?05232018);
  background-position: -240px 0px;
  width: 48px;
  height: 48px;
}

.icon-home-ball {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/icons.png?05232018);
  background-position: -176px -160px;
  width: 48px;
  height: 48px;
}

.icon-home-large {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/icons.png?05232018);
  background-position: -160px 0px;
  width: 80px;
  height: 80px;
}

.icon-map-ball {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/icons.png?05232018);
  background-position: -80px -160px;
  width: 48px;
  height: 48px;
}

.icon-payment-large {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/icons.png?05232018);
  background-position: 0px 0px;
  width: 80px;
  height: 80px;
}

.icon-repair-ball {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/icons.png?05232018);
  background-position: -128px -160px;
  width: 48px;
  height: 48px;
}

.icon-repair-large {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/icons.png?05232018);
  background-position: -80px -80px;
  width: 80px;
  height: 80px;
}

.icon-restricted-large {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/icons.png?05232018);
  background-position: 0px -80px;
  width: 80px;
  height: 80px;
}

.icon-station {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/icons.png?05232018);
  background-position: -240px -48px;
  width: 48px;
  height: 48px;
}

.icon-warn-large {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/icons.png?05232018);
  background-position: 0px -160px;
  width: 80px;
  height: 80px;
}

.icon-A {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/markers.png?12202018);
  background-position: 0px 0px;
  width: 64px;
  height: 88px;
}

.icon-AR {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/markers.png?12202018);
  background-position: -64px 0px;
  width: 64px;
  height: 88px;
}

.icon-B {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/markers.png?12202018);
  background-position: -128px 0px;
  width: 64px;
  height: 88px;
}

.icon-D {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/markers.png?12202018);
  background-position: -192px 0px;
  width: 64px;
  height: 88px;
}

.icon-G {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/markers.png?12202018);
  background-position: -256px 0px;
  width: 64px;
  height: 88px;
}

.icon-GR {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/markers.png?12202018);
  background-position: -320px 0px;
  width: 64px;
  height: 88px;
}

.icon-H {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/markers.png?12202018);
  background-position: -384px 0px;
  width: 64px;
  height: 88px;
}

.icon-M {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/markers.png?12202018);
  background-position: -448px 0px;
  width: 64px;
  height: 88px;
}

.icon-Y {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/markers.png?12202018);
  background-position: -512px 0px;
  width: 64px;
  height: 88px;
}

.icon-YR {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/markers.png?12202018);
  background-position: -576px 0px;
  width: 64px;
  height: 88px;
}

.icon-Blink {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: 0px -60px;
  width: 120px;
  height: 60px;
}

.icon-ChargeNet {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: -240px -180px;
  width: 120px;
  height: 60px;
}

.icon-ChargePoint {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: -120px 0px;
  width: 120px;
  height: 60px;
}

.icon-Chargefox {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: -120px -60px;
  width: 120px;
  height: 60px;
}

.icon-China-Southern-Power-Grid {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: 0px -120px;
  width: 120px;
  height: 60px;
}

.icon-Circuit-Electrique {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: -120px -120px;
  width: 120px;
  height: 60px;
}

.icon-EV-Connect {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: 0px -180px;
  width: 120px;
  height: 60px;
}

.icon-EVgo {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: -120px -180px;
  width: 120px;
  height: 60px;
}

.icon-Electrify-America {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: -240px 0px;
  width: 120px;
  height: 60px;
}

.icon-FLO {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: -240px -60px;
  width: 120px;
  height: 60px;
}

.icon-GE-WattStation {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: -240px -120px;
  width: 120px;
  height: 60px;
}

.icon-Greenlots {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: 0px 0px;
  width: 120px;
  height: 60px;
}

.icon-Innogy {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: 0px -240px;
  width: 120px;
  height: 60px;
}

.icon-JNSH {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: -120px -240px;
  width: 120px;
  height: 60px;
}

.icon-Other {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: -240px -240px;
  width: 120px;
  height: 60px;
}

.icon-POLAR {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: 0px -300px;
  width: 120px;
  height: 60px;
}

.icon-Potevio {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: -120px -300px;
  width: 120px;
  height: 60px;
}

.icon-SemaCharge {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: -240px -300px;
  width: 120px;
  height: 60px;
}

.icon-State-Grid-Corporation-of-China {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: -360px 0px;
  width: 120px;
  height: 60px;
}

.icon-Sun-Country {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: -360px -60px;
  width: 120px;
  height: 60px;
}

.icon-Webasto {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: -360px -120px;
  width: 120px;
  height: 60px;
}

.icon-myEVroute {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/networks.png?10182018);
  background-position: -360px -180px;
  width: 120px;
  height: 60px;
}

.icon-image00 {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/outlets.png?08292018);
  background-position: -97px 0px;
  width: 96px;
  height: 96px;
}

.icon-image01 {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/outlets.png?08292018);
  background-position: -194px -194px;
  width: 96px;
  height: 96px;
}

.icon-image02 {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/outlets.png?08292018);
  background-position: 0px -97px;
  width: 96px;
  height: 96px;
}

.icon-image03 {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/outlets.png?08292018);
  background-position: -97px -97px;
  width: 96px;
  height: 96px;
}

.icon-image04 {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/outlets.png?08292018);
  background-position: -194px 0px;
  width: 96px;
  height: 96px;
}

.icon-image05 {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/outlets.png?08292018);
  background-position: -194px -97px;
  width: 96px;
  height: 96px;
}

.icon-image06 {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/outlets.png?08292018);
  background-position: 0px -194px;
  width: 96px;
  height: 96px;
}

.icon-image08 {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/outlets.png?08292018);
  background-position: -97px -194px;
  width: 96px;
  height: 96px;
}

.icon-image09 {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/outlets.png?08292018);
  background-position: 0px 0px;
  width: 96px;
  height: 96px;
}

.icon-image10 {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/outlets.png?08292018);
  background-position: -291px 0px;
  width: 96px;
  height: 96px;
}

.icon-image12 {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/outlets.png?08292018);
  background-position: -291px -97px;
  width: 96px;
  height: 96px;
}

.icon-image13 {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/outlets.png?08292018);
  background-position: -291px -194px;
  width: 96px;
  height: 96px;
}

.icon-image14 {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/outlets.png?08292018);
  background-position: 0px -291px;
  width: 96px;
  height: 96px;
}

.icon-image15 {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/outlets.png?08292018);
  background-position: -97px -291px;
  width: 96px;
  height: 96px;
}

.icon-image16 {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/outlets.png?08292018);
  background-position: -194px -291px;
  width: 96px;
  height: 96px;
}

.icon-image17 {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/outlets.png?08292018);
  background-position: -291px -291px;
  width: 96px;
  height: 96px;
}

.icon-image23 {
  background-image: url(https://s3.amazonaws.com/plugshare.production.assets/assets/outlets.png?08292018);
  background-position: -388px 0px;
  width: 96px;
  height: 96px;
}

/**
 *  Do not remove the comment below. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
